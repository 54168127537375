.chatbox {
position: fixed;
width: 45rem;
background-color:#fff;
right: 2rem;
bottom: 8rem;
box-shadow: 0 2px 4px rgba(0,0,0,.16);
padding-bottom: 3rem;
z-index: 2147483007;
transition: all .3s ease-out;


 &__close {
     position: absolute;
     color: $gray-hex;
     z-index: 5;
     right: 1rem;
     top: 1rem;
     cursor: pointer;
     &__icon {
         font-size: 1.5rem;
     }

 }
          

}


.chatbox__top{
 background-color:$secondary-hex;
 padding: 3rem;
 padding-bottom: 4.5rem;
   

    &__heading {
        font-size: 3.2rem;
       
    }

    &__paragraph {
        font-size: 1.6rem;
    }
}

.chatbox__bottom {
    

    .btn__chat__primary_1{
        color: $secondary-hex;
        background-color: $gray-hex;
        width: 25rem;
        padding: 1rem 2rem;
        margin-top: 1.5rem;
        border-radius: 2rem;
        font-size: 2rem;
        cursor: pointer;



        &:focus {
            outline: none;
        }

       

    }
}
.chatbox__bottom__enquiry-staff {
    background-color: #fff;
    // border-top:2px solid $gray-hex;

        
       


        &__white_top{
            width: 38rem;
            margin: 0 auto;
            background-color: #fff;
            transform: translateY(-3rem);
            border-radius: 5px;
            padding: 1.5rem 1.5rem 3rem;
            box-shadow: 0 2px 4px rgba(0,0,0,.16);
            border-top:2px solid $gray-hex;
        }
       &__heading {
           font-size: 2rem;
           padding-top:0;
           margin-top: 0;
       }
      &__paragraph {
          font-size: 1.6rem;
      }
}
        
.chatbox__bottom__staff-images {
    &__img {
        width:6rem;
        height: 6rem;
        border-radius: 100px;
        border: 2px solid #fff;


        &:not(:first-child) {
            margin-left: -1.5rem;
        }
    }
}
        
.chatbox__bottom__main-chat-box {
    background-color: #fff;
    border-top:2px solid $gray-hex;
    border-radius: 3px;
    width: 42rem;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0,0,0,.16);
}
.chatbox__form {
    padding: 3rem 1.5rem;
    
    
    &__group {

   margin-bottom: 1.5rem;
    }

    &__control {
        font-family: inherit;
        padding: 1.2rem 2rem;
        width: 100%;
        font-size: 1.6rem;
        

    }

   

.btn {

    color: $secondary-hex;
    background-color: $gray-hex;
    width: 20rem;
    margin-top: 1.5rem;
    font-size: 2.6rem;


    @include respond(xl) {
        font-size: 1.8rem;
    }
} 
.btn__chat__primary{}
}


.hide {
    visibility: hidden;
    transform: translateX(100%);
    opacity: 0;
}
.show {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
}




             
                 