.politicalPartyAdmin{

padding: 0 6rem;

    .h3 {
        font-size: 2.4rem;
        margin:3rem auto;
        width: 80%;
        
    }

    &__inner {

    }

    &__btn {
        cursor: pointer;
        background-color: #038c73;
        color: #fff;
        padding: 10px 20px;
        font-size: 1.5rem;
        letter-spacing: 2px;
        border: none;
        width: 80%;
        margin: 0 auto;
        display: block;
    }

    &__trash {
        cursor: pointer;
    }

    &__table {
        width: 80%;
        margin: auto;
        border-collapse: collapse;

        td, th, tr {
            border: 1px solid #4F4C5D;
            font-size: 1.6rem;
            padding: 1rem 1.5rem;
        }
    }

    &__trash {
        width: 100%;
        border: 0;

        &:focus {
            outline: none;
        }
    }
}