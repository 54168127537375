.adhoc {
    background-color: #eee;
    // padding: 6rem 3rem;
    min-height: 70vh;
    padding: 6rem 5%;
    &__container{
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
        border-radius: 10px;
       
    }

    @include respond(lg) {
        padding: 6rem 20%;
    }

    &__inner {
        padding: 3rem;
     
        

        h2 {
            margin-bottom: 6rem;
            font-size: 2.2rem;
            // text-transform: uppercase;
            letter-spacing: 1px;
            width: 80%;
            margin: 0 auto 1.5rem auto;

            @include respond(lg) {
                width: 95%;
            }
        }



        .alert {
            position: relative;
            padding: .75rem 1.25rem;
            margin: auto;
            margin-bottom: 1rem;
            border: 1px solid transparent;
            border-radius: .25rem;
            width: 70%;
            font-size: 1.6rem;
             
        }
    
        .alert__primary {
            color: #004085;
            background-color: #cce5ff;
            border-color: #b8daff;
        }
    
        .alert__success {
            color: #155724;
            background-color: #d4edda;
            border-color: #c3e6cb;
        }
    }

    .adhoc__form {
        // width: 82%;
        margin: 0 auto;


        .invalid {
            background-color: rgb(248, 154, 154);
            border: 1px solid red;
        }
    }

    

    .adhoc__form__group {
        margin-bottom: 3rem;
        
    }

    .bm {
        margin-bottom: 3rem;
    }

    .cbm {
        // margin-bottom: 1rem;
    }

    .adhoc__form__label {
        display: inline-block;
        margin-bottom: .5rem;
        font-size: 1.6rem;
        letter-spacing: 1px;
        color: rgb(0, 85, 0);
        font-weight: bold;

       
    }

   
    .adhoc__form__control {
        display: block;
        width: 100%;
        height: calc(2.5em + .75rem + 2px);
        padding: 1rem 2rem;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #005007;
        border-radius: 0.5rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;   

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }
    }

    .adhoc__form__group__radio {
        display: flex;
        align-items: center;
        color: rgb(0, 85, 0);
        font-weight: bold;
        line-height: 1.6;

        input:disabled, input:disabled + label {
            color: gray;
            cursor: not-allowed;
        }
    }

    .adhoc__form__radio{
        font-size: 1.4rem;
        margin-left: 1.5rem;
        color: rgb(0, 85, 0);
        font-weight: bold;
        letter-spacing: 1px;


       
    }

.textarea {
            width: 100%;
            padding: 1.5rem 2rem;
            font-size: 1.6rem;

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
            }
        }
    .adhoc__form__groupoptext {
        margin: 3rem 0;


        
    }

    input[type=file] {
        display: block;
        padding: 0 2rem;
        width: 100%;
        font-size: 1.2rem;


        &:focus {
            outline: none;
        }
    }

    .mt {
        margin-top: 3rem;
        border-radius: 5px;
    }
    .bft {
        font-size: 1.4rem;

    }

    .mb0 {
        margin-bottom: 0;
    }

    .bgt {
        background-color: $gray-hex;
        color: #fff;
        padding: 6px;
    }

    .submit {
        padding: 1.5rem 2rem;
        font-size: 1.5rem;
        background-color: $primary-hex;
        border: 0;
        width: 80%;
        margin: auto;
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 1.5rem;
        letter-spacing: 1px;
        margin-top: 3rem;
        border-radius: 5px;

        @include respond(md) {
            width: 40%;
        }

        &:hover {
            background-color: lighten($primary-hex, 10);
        }

        &:focus {
            background-color: lighten($primary-hex, 10);
            outline: 0;
        }

        &:disabled {
            background-color: #ccc;
            cursor:not-allowed;
        }
    }
}

.confirmation {
    width: 90%;
    margin:auto;
    
}
.confirmation__inner {
    margin-top: 1.5rem;

    p {
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
    }

}

.adhoc__confirmation__heading {
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: center;
    margin: 3rem auto;


    img {
        width: 75px;
        height: 75px;
    }
    h2 {
        font-size: 1.8rem;
        text-align: center;

        
    }

    span{
        display: block;
    }
}

.w-80{
    width: 90%;
    margin:auto;
}
.tcenter {
    margin-top: 3rem;

}

.ionicon-information{
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.icon-information-pop {
    width: 90%;
    margin: auto;

    h3 {
        margin-bottom: 3rem;
        font-size: 2rem;
    }
    ol {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
    }

    @include respond(md) {
        width: 70%;
    }

    .bold {
        font-weight: bold;
    }
}