.pressReleasep {

    .invalid {
        border: 1px solid red;
    }

    &__btn {
        cursor: pointer;
        background-color: $primary-light-rgba;
        color: $white;
        padding: 10px 20px;
        font-size: 1.5rem;
        letter-spacing: 2px;
        border: none;

        &:focus {
            outline: none;
        }
    }


    &__input {
        width: 90%;
        display: flex;
        margin-bottom: 6rem;
        justify-content: flex-end;
        margin-top: 30px;


        &__search {
            padding: 12px 20px;
            font-size: 1.5rem;
            letter-spacing: 2px;
            border: 1px solid $primary-hex;
            width: 30rem;

            &:focus {
                outline: none;
            }
        }

    }
}
.pressReleasep__main {


    &__add {

        padding: 3rem;


        .pr {
            width: 60%;
            margin: auto;

            &__group {
                margin-bottom: 3rem;
            }

            &__plusMinus__btn {
                padding: 10px 20px;
                margin-left: 10px;
                font-size: 1.6rem;
                background-color: $primary-hex;
                border: none;
                color: #fff;
                cursor: pointer;

                &:focus {
                    outline: none;
                }


            }
            &__plusMinus__mmm {
                padding: 10px 20px;
                margin-left: 10px;
                font-size: 1.6rem;
                background-color: $primary-hex;
                border: none;
                color: #fff;
                display: block;
                margin-left: auto;
                cursor: pointer;
                margin-top: 20px;

                &:focus {
                    outline: none;
                }


            }

            &__h3 {
                letter-spacing: 2px;
                color: $gray-hex;
                font-weight: 700;
            }

            &__label {
                display: block;
                width: 35%;
                margin-left: auto;
                color: $gray-hex;

                &__span {
                    background-color: $primary-hex;
                    padding: 10px 20px;
                    margin-left: 10px;
                    color: $white;
                }
            }

            .pr__control {
                display: block;
                width: 100%;
                padding: 1.5rem 2rem;
                font-size: 1.5rem;
                letter-spacing: 2px;
                border: 1px solid $gray-hex;

                &:focus {
                    outline: none;
                    border: 1px solid $primary-light-rgba ;
                }
            }

            .pr__control.invalid {
                border: 1px solid red;
            }

            .pr__tacontrol {
                display: block;
                width: 100%;
                font-size: 1.5rem;
                letter-spacing: 2px;
                padding: 1.5rem 2rem;
                border: 1px solid $gray-hex;

                &:focus {
                    outline: none;
                    border: 1px solid $primary-light-rgba;
                }
            }

            .pr__tacontrol.invalid {
                border: 1px solid red;
            }

            .pr__btn__primary {
                background-color: $primary-hex;
                display: block;
                width: 70%;
                margin: auto;
                padding: 1.5rem 2rem;
                border:none;
                letter-spacing: 2px;
                font-size: 1.5rem;
                color: #fff;
                cursor: pointer;
            }
        }
    }

    &__view {


    }
}


.pressReleasep__main__view__box{

   
}
.pressReleasep__main__view__box__inner{

    width: 90%;
    margin: auto;
    background-color: #fff;
    padding: 3rem;
    box-shadow: 0 1px 3px rgba(0,0,0,.12);
    margin-bottom: 3rem;

    @include respond(lg) {
        width: 50%;
    }

    &__mainHeading {
        font-size: 1.6rem;
        text-transform: capitalize;
        letter-spacing: 1px;

    }

    &__body {
        font-size: 1.4rem;
        // font-weight: 300;
        margin: 3rem 0;
        letter-spacing: 1px;
        text-align: justify;

        &__btn {
            margin-right: 3rem;
            cursor: pointer;
            padding: 12px 20px;
            background-color: none;
            font-size: 1.4rem;
            letter-spacing: 1px;
            border: 0;
        }
    }
}

.pressReleasep__main__view__box__inner__body__footer {

}

.pressReleasep__main__view__box__inner__body__footer__lg{
    width: 50%;
    margin-left: auto;
    text-align: right;
    margin-bottom: 3rem;

    &__paragraph {
        font-size: 1.4rem;
        // font-weight: 200;
        color: $gray-hex;
    }
}
.kjg {
    background-color: #f1f1f1;
    padding:0 0 6rem 0;
}
.nnbg {

}

