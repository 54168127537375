.mv {
	display: flex;
	background-color: #f1f1f1;
	padding: 6rem 3rem;
	flex-direction: column;

	@include respond(sm) {
		flex-direction: row;
	}

	&__mission {
		margin-right: 3rem;
		flex: 0 0 40%;

		&__first {
			margin-bottom: 3rem;
		}

		&__second {
			margin-bottom: 3rem;
		}

		hr {
			margin-bottom: 1.5rem;
			border: 1px solid rgba($gray-hex, 0.15);
		}
	}

	h2 {
		font-size: 2.1rem;
		margin-bottom: 1.5rem;
		text-transform: uppercase;
		letter-spacing: 1px;
		color: $gray-hex;
	}

	p {
		font-size: 2rem;
		letter-spacing: 1px;

		@include respond(lg) {
			font-size: 1.5rem;
		}

		span {
			font-size: 2rem;
		}

		.span {
			font-size: 1.5rem;
			font-weight: bold;
			text-decoration: underline;
		}
	}

	.p {
		width: 90%;
	}

	&__statutory {
		margin-left: 3rem;
		flex: 1;

		.par {
			margin-bottom: 1.5rem;
			width: 90%;
		}

		ul {
			width: 90%;
			padding-left: 3rem;

			list-style-type: lower-roman;
		}

		li {
			font-size: 2rem;
			margin-bottom: 1.5rem;
			letter-spacing: 1px;

			@include respond(lg) {
				font-size: 1.5rem;
			}

			.span {
				font-weight: bold;
				text-decoration: underline;
			}
		}
	}
}
