.section-mission{
    // margin-bottom: 3rem;



    @include respond(md) {
        // margin-bottom: 4.7rem; 
    }
    &-inner{
    max-width: 140rem;
    margin:0 auto;
    background-color: $gray-hex;
    padding:3rem;
    }


}

.mission{


    display: flex;
   
    background-color: $gray-hex;
    justify-content: space-between;
    flex-direction: column;

    @include respond(md){
        flex-direction: row;
       
    }



}
.mission__story{
    position: relative;
    padding:3rem;
    box-shadow: 0 2px 4px rgba(0,0,0, .06);
    background-color:#fff;
    flex: 1;
    margin-bottom: 3rem;
     font-size:2.4rem;
    line-height: 1.7;
    text-align: justify;

    @include respond(md){
        
        margin-left: 3rem;
        margin-bottom: 0;
        font-size:1.6rem;
    }


    h2 {
        font-size: 3.5rem;


        @include respond(md) {
            font-size: 2.4rem;
        }
    }
   
  

  

    p  {
       margin-bottom: 3rem;
   

      
    }
    
    &__src {
        border-radius: 100px;
        width:75px;
        height: 75px;
        margin-right: 3rem;

    }


    &__image {
        display: flex;
        align-items: center;


        p {
            margin-bottom: 0;
            span {
                display: block;
            }
        }
    }


    &::after {
        
        // @include respond(lg) {

        // content: '';
        // height: 300px;
        // display: block;
        // width: 2px;
        // background-image: linear-gradient(to bottom, rgba($gray-hex, .1) 0%, rgba($gray-hex, 1) 45%, rgba($gray-hex, 1) 55%,  rgba($gray-hex, .1) 100%);
        // position: absolute;
        // right: -35px;
        // top:50px;
        
        // }
    }
}


.mission__gallery{

 

    background-color: #fff;
    padding:3rem;
    padding-bottom: 0;
    flex: 0 0 calc(60% - 3rem);
    order: -1;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;


    @include respond(md){
        flex-direction: row;
    }


  

    &__image{

        width: 100%;
        height:165px;
        margin-bottom: 3rem;
        position: relative;
       


        @include respond(sm){
           
      
        }
        @include respond(md){
            width: calc(100% / 2 - 1.5rem);
        
           
        }
 

        &:hover &__overlay {
            visibility: visible;
        }

        &:hover p {
            visibility: visible;
        }


        p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            visibility: hidden;
            font-size: 3.4rem;
            color: $gray-hex;
            text-align: center;
            width: 100%;
        }

        &__overlay{
            position: absolute;
            top:0;
            left:0;
            bottom: 0;
            right: 0;
            z-index: 5;
            background-color:rgba(#fff, .8);
            visibility: hidden;
        }
    }

    &__src {
        width: 100%;
        height: 165px;
        object-fit: cover;
    }

   
}

