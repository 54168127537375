.sidebar {
	position: fixed;
	z-index: 700;
	width: 90%;
	top: 0;
	left: 0;
	height: 100%;
	background-color: #fff;
	transition: all 0.3s ease-out;

	@include respond(lg) {
		display: none;
	}

	span {
		position: absolute;
		top: 5px;
		right: -35px;
		cursor: pointer;
		background-color: rgba(#fff, 0.9);

		@include respond(lg) {
			display: none;
		}
	}

	hr {
		width: 80%;
		margin: 0 auto;
		background-color: #f2f4f4;
		height: 0.5px;
		overflow: hidden;
	}

	&__inner {
		display: flex;
		flex-direction: column;
		height: 100%;
		background-color: $gray-hex;
		overflow: scroll;
	}

	&__brand {
		background-color: #fff;
		padding: 3rem;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);

		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-around;
		}
	}
	&__brand--lagos {
		width: 12rem;
		display: block;

		img {
			width: 100%;
			height: 100px;
			object-fit: contain;
		}
	}
	&__brand--lasiec {
		width: 9rem;
		display: block;

		img {
			width: 100%;
			height: 100px;
			object-fit: contain;
		}
	}

	&__brand--text {
		color: $gray-hex;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-start;

		h1 {
			font-size: 4.5rem;
			text-transform: uppercase;
			letter-spacing: 2rem;
			font-weight: 600;
		}
		.first {
			font-size: 2.4rem;
			letter-spacing: 8.7px;
			text-transform: uppercase;
			font-weight: 600;
		}

		.second {
			font-size: 2rem;
			font-weight: 600;
		}
	}

	&__navigation {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin: 6rem 0;

		padding-bottom: 6rem;
	}
	&__navigation--menu {
		margin-bottom: auto;
		list-style: none;
		padding-left: 0;

		li {
			position: relative;

			width: 100%;

			// &::before {
			// 	content: '';
			// 	display: block;
			// 	position: absolute;
			// 	height: 100%;
			// 	width: 3px;
			// 	background-color: $secondary-hex;
			// 	transform: scaleY(0);
			// 	transition: transform 0.2s,
			// 		width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
			// }

			&:not(:last-child) {
				margin-bottom: 0.5rem;
			}

			// &:hover::before,
			// &.active::before {
			// 	transform: scaleY(1);
			// 	width: 100%;
			// }

			&.active::before {
				background-color: darken($secondary-hex, $amount: 5);
			}

			&:hover a {
				
				font-weight: 600;
			}
			&.active a {
				color: $gray-hex;
				font-weight: 600;
			}

			a {
				font-size: 2.4rem;
				text-decoration: none;
				display: block;
				padding: 2rem 6rem;
				font-weight: 400;
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 2px;
				position: relative;
				z-index: 740;
			}
		}
	}
	&__dropdown__menu {
		display: none;
	}
	&__dropdown__item:hover {
		background-color: lighten($color:$gray-hex , $amount: 30) ;
		color: #eee;
	}

	&__dropdown__toggle {
		// margin-right: 45px;
		display: inline-block;

		&::after {
			content: '';
			display: inline-block;
			border: 5px solid #fff;
			border-color: #fff transparent transparent transparent;
		}

		// &:hover::after {
		// 	border-color: $gray-hex transparent transparent transparent;
		// }
	}

	&__navigation--social {
		margin-top: 3rem;
		align-self: center;
		display: flex;
		list-style: none;
	}
	.accordion {
		transition: 0.4s;
		letter-spacing: 1px;
	}

	/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
	.active {
		background-color: $secondary-hex;
		color: $gray-hex;
	}

	/* Style the accordion panel. Note: hidden by default */
	.panel {
		// padding: 0 18px;
		// background-color: lighten($color: $gray-hex, $amount: 10);
		max-height: 0;
		overflow: hidden;
		font-size: 1.5rem;
		transition: max-height 0.3s ease-out;
		border-left: 2px inset $gray-hex;
		border-right: 2px ridge $gray-hex;
		box-sizing: border-box;
		margin: 0 1px;
	}
}

.showSidebar {
	// opacity: 1;
	transform: translateX(0);
}

.hideSidebar {
	// opacity: 0;
	transform: translateX(-150%);
}
