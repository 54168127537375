
:root {
    font-size: 42.5%;
    box-sizing: border-box;

    
    // @include respond (sm) {
    //     font-size: 42.5%;
    // }
    @include respond (md) {
        font-size: 48.5%;
    }
    @include respond (lg) {
        font-size: 50.5%;
    }
    @include respond (xl) {
        font-size: 62.5%;
    }
}

*,
*::before,
*::after {
    margin:0;
    padding:0;
    box-sizing: inherit;
}

body {
    font-family: 'Lato', sans-serif;
    letter-spacing: .03rem;
    background-color: $white;
    line-height: 1.5;
    font-weight: 400;
    background-color: $white;
    margin-top: 13rem;


    @include respond(sm) {
        margin-top: 12rem;
    }

    @include respond(lg) {
        margin-top: 11rem;
    }
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Crimson Text', serif;
    letter-spacing: .05rem;
  
}

h3.h3 {
    font-size: 2.2rem;
    margin-bottom: 3rem;
    
}

img {
    max-width: 100%;
    height:100%;
}

.text-center {
    text-align:center !important;
}

.text-dash {
    font-size: 5rem !important;

    @include respond(md) {
        font-size: 2rem !important;
    }
}

.text-dash-standalone {
    font-size: 2.8rem !important;
    display:inline-block;
    margin-left:auto;
}

.text-uppercase {
    text-transform:uppercase !important;
}


.email-box {
    position: absolute;
    top: 27%;
    font-size: 4rem;
    color: $white;
    left: 7rem;
    --ionicon-stroke-width: 16px;
}
.banner__image {
    // margin-bottom: 3rem;
    object-fit: cover;
    background-position: 50% -100%;
}

.vs_ab_alert {
	position: absolute;
	width: 50%;
	left: 25%;
	top: 50%;
}


