.chairmanshipFormTemplate {
	&__fieldset {
		margin: 2.5rem auto;
		padding: 3rem;

		legend {
			font-size: 1.8rem;
            color: $primary-light-hex;
            font-weight: 700;
            text-transform: uppercase;
		}

		ul {
			list-style: none;
			width: 80%;
			margin: auto;
		}

		li {
			margin: 2rem 0;
		}

		label {
            font-size: 1.6rem;
            color: $primary-light-hex;
            font-weight: 500;
		}
	}
}

.result__input__field {
	display: block;
	width: 100%;
	padding: 1.5rem 3rem;
    font-size: 1.5rem;
    border: 1px solid $primary-light-hex;

    
    &:focus {
        outline: none;
        border: 1px solid darken($color: $primary-light-hex, $amount: 20) ;
    }
}
.result__select__field {
	display: block;
	width: 100%;
	padding: 1.5rem 3rem;
    font-size: 1.5rem;
    border: 1px solid $primary-light-hex;


    &:focus {
        outline: none;
        border: 1px solid darken($color: $primary-light-hex, $amount: 20) ;
    }
    
}

.result__btn {
    width: 23rem;
    border-radius: 10px;
    background-color: $primary-light-hex;
    border: 1px solid $primary-light-hex;
    color: #fff;
    padding: 1.7rem 2.4rem;
    font-size: 1.6rem;
    margin-right: 4rem;
    cursor: pointer;

    &:hover {
        transform: translateY(1px);
    }

    &:focus {
        outline: none;
        // border: 1px solid darken($color: $primary-light-hex, $amount: 20)
    }
}

.result__reverse {
    background-color: #fff;
    color: red;
}

.result__button__box {
    text-align: center;
}
