.nav {
    box-shadow: 0 2px 4px rgba(0,0,0,.15);
    position:fixed;
    z-index: 500;
    width: 100%;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 1rem 0;
    background-color: #fff;
    top:0;
    left:0;

   
   
    &__inner {
        display: flex;
        align-items: center;
        max-height: 10rem;
        max-width: 140rem;
        margin:0 auto;
    }
  
}

.nav__toggler-icon {
   
    width: 35px;
    height: 35px;
    position: absolute;
    top: 20px;
    right: 10px;
    border: 1px solid $gray-hex1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    @include respond(lg) {
        display: none;
    }
}

.nav__navigation {

    display: none;

    @include respond(lg) {
        display: flex;
        margin-left: auto;
     

    &--menu {
        display: flex;
        margin-left: auto;
        list-style: none;
        padding-left: 0;
        text-transform: uppercase;


        & > li {
            // display: inline-block;
            // padding: 1rem 1.4rem;
        }

        // & > li.dropdown {
        //     display: flex;
        //     align-items: center;
        // }

        & > li > a:link, & > li > a:visited {
            display: inline-block;
            padding: 1rem 2rem;
            text-decoration: none;
            color: $color-threme-grey-4-hex;
        }

        & > li > a:active, & > li > a:focus,& > li > a:hover, & > li > a.active {
            
                background-color: $gray-hex;
                color: #fff;
            
        }

        

        & > li {

            @include respond(md) {
                margin-right: .75rem;
            }
        }
    }

    &--social {
        display: flex;
        padding-left: 0;
        list-style: none;
        font-size: 2rem;

        & > li + li {
            display: inline-block;
            margin-left: 1rem;
          
        }
    }
    }
}

.instagram {
    height:2rem;
    width:2rem;
}

.facebook {
    color: $color-facebook;
}

.twitter {
    color:$color-twitter;
}

.youtube {
    color: $color-youtube;
}


.nav__brand {
    display: flex;
    align-items: center;
    text-decoration: none;

    &--lagos {
        width: 7.5rem;
        display: block;
        height: 7.5rem;
        margin-right: 1.2rem;
    }

    &--lasiec {
    display: block;
    display: flex;
    text-align: left;
    align-items: center;
    text-decoration: none;
    justify-content: flex-start;
    color: $gray-hex;
    width: 7rem;
    height: 7.4rem;
    margin-right: 1rem;


    &:hover {
        text-decoration: none;
    }
   
    &-logo {
        width: 4rem; 
        height: 4.5rem;
        margin-right:1rem;
    }


   
}


&--text {
    margin-top:22px;
    
    h1 {
       text-transform: uppercase;
       font-size: 2.2rem;
       font-weight: bolder;
       color: lighten($color: $gray-hex, $amount: 10);
       margin-bottom: -.5rem;

    }
    p {
        font-size: 1rem;
        font-weight: bold;
        color: lighten($color: $gray-hex, $amount: 30);
       
    }

    p.first {
       text-transform: uppercase;
       letter-spacing: 2px;
    }

    p.second {
  
        letter-spacing: 1.1px;
    }
}

} 


.dropdown {

 position: relative;


    &__toggle {
        position: relative;
        

        &::after {
            content: '';
            border-width: 5px;
            border-style: solid;
            border-color: #000 transparent transparent transparent;
            position:absolute;


          

            @include respond(md) {
                top: 12px;
                right: 2px;
            }

            @include respond(xl) {
                top: 18px;
                right: 5px;
            }
        }

        

        .dropdown:hover &::after {
            border-color: transparent transparent  #fff transparent;
            top: 14px;

            @include respond(md) {
                top: 9px;
            }
            @include respond(xl) {
                top: 14px;
            }
        }
        
        .dropdown &.active::after {
            border-color: transparent transparent  #fff transparent;
            top: 14px;

            @include respond(md) {
                top: 9px;
            }
            @include respond(xl) {
                top: 14px;
            }
        }

        .dropdown:hover & {
            background-color: $gray-hex;
            color: #fff;
        }
        


    }

    &__menu {
        position: absolute;
        background-color: $gray-hex;
        min-width: 24rem;
        display: none;
        border: 1px solid lighten($gray-hex, $amount: 10);

       

        .dropdown:hover > & {
            display: block;
        }

     
    }

  

    &__item {
        display: block;
        word-break: initial;
        padding: 1rem 1.6rem;
        background-color: $gray-hex;
        text-decoration: none;
        color: #fff;
        text-transform: initial;
        font-size: 1.6rem;


        &:hover {
            background-color: lighten($gray-hex, $amount: 10);
            color: #f2f2f2;
        }


        & + .dropdown__item {
            border-top: 1px solid lighten($gray-hex, $amount: 10);
        }
      
    }

    

}

.nav__navigation--menu__admin {
        display: flex;
        list-style: none;

        a {
            text-decoration: none;
            margin-left: 10px;
        }
    }
