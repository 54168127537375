.container__inner {
  background-color: #f1f1f1;
  padding: 3rem 3rem 6rem 3rem;

  .innerHeading {
    font-size: 3.2rem;
    margin-bottom: 6rem;
    position: relative;
    letter-spacing: 2px;

    &::after {
      content: "";
      display: block;
      background-color: rgba($gray-hex, 0.25);
      margin-top: 1rem;
      height: 1px;
      width: 100%;
    }
  }

  &__bg {
    width: 90%;
    margin: auto;
  }
}

.contactUs {
  display: flex;
  padding-bottom: 6rem;
  color: $gray-hex;
  flex-direction: column;

  @include respond(lg) {
    flex-direction: row;
  }

  h3 {
    font-size: 20px;
    letter-spacing: 2px;
    margin-bottom: 15px;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 90px;
      background-color: $gray-hex;
      margin-top: 10px;
    }
  }

  h5 {
    font-size: 14px;
    letter-spacing: 2px;
  }

  .invalid {
    border: 1px solid red;
    background-color: rgb(247, 132, 132);
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .alert__primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }

  .alert__success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .pf {
    font-size: 1.6rem;
  }

  &__address {
    flex: 0 0 300px;
    margin-bottom: 6rem;
    @include respond(lg) {
        margin-right: 6rem;
        margin-bottom: 0;
    }

    p {
      margin-top: 30px;
      font-size: 1.6rem;
      font-weight: 600;
      letter-spacing: 2px;

      span {
        font-weight: 400;
      }

      span:first-child {
        display: block;
        padding-left: 15px;
      }
    }
  }

  &__enquiry {
    flex: 1;
    background-color: #fff;
    padding: 3rem;
    box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.12);

    h2 {
      letter-spacing: 2px;
      font-size: 20px;
      margin-bottom: 30px;

      &::after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background-color: $gray-hex;
        margin-top: 10px;
      }
    }

    .fullname,
    .subject {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      @include respond(sm) {
          flex-direction: row;
           justify-content: space-between;
      }

      & > * {
        flex: 0 1 48%;

      }

      & > input:first-of-type {

        margin-bottom: 30px;

        @include respond(sm) {
           margin-bottom: 0;
        }

      }
    }

    .subject {
    }

    .form__control {
      padding: 15px 20px;
      font-size: 15px;
      color: $gray-hex;
      border: 1px solid $gray-hex;

      &:focus {
        outline: none;
        border: 1px solid darken($color: $gray-hex, $amount: 10);
      }
    }

    .form__textarea {
      padding: 15px 20px;
      font-size: 15px;
      color: $gray-hex;
      width: 100%;
      margin-bottom: 30px;
      border: 1px solid $gray-hex;

      &:focus {
        outline: none;
        border: 1px solid darken($color: $gray-hex, $amount: 10);
      }
    }

    button {
      padding: 15px 20px;
      font-size: 16px;
      color: $secondary-hex;
      width: 100%;
      background-color: $gray-hex;
      border: none;
      cursor: pointer;
      text-transform: uppercase;

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  }
}

.offices {
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.12);
  color: $gray-hex;
  flex-direction: column;

  @include respond(lg) {
    flex-direction: row;
    
}
  h2 {
    font-size: 3.2rem;
    position: relative;
    letter-spacing: 2px;
  }
  &__list {
    flex: 1;
    padding: 3rem 0 3rem 6rem;
    ul {
      padding-left: 0;
      margin-bottom: 15px;
      list-style: none;

      @include respond(md) {
          padding-left: 30px;
      }
    }
    li {
      margin-top: 10px;
      font-size: 14px;
      display: flex;
      align-items: center;
      letter-spacing: 2px;
    }

    button {
      padding: 15px 20px;
      font-size: 15px;
      color: $secondary-hex;
      width: 90%;
      background-color: $gray-hex;
      border: none;
      cursor: pointer;
      text-transform: uppercase;

      @include respond(md) {
        width: 50%;
      }
    }

    p {
      font-size: 15px;
      padding-left: 0;
      padding-right: 15px;

      @include respond(md) {
        padding-left: 15px;
        padding-right: 0;
      }

      .offices {
        font-weight: 700;
        display: inline-block;
        box-shadow: none;
      }
    }
  }

  &__map {
    flex: 1;
    background-image: url("../../Images/lasieconthemap.jpg");
    background-size: cover;
    padding: 1.5rem;
    object-fit: contain;
    background-position: center;
    width: 100%;
    min-height: 400px;

    @include respond(lg) {
        min-height: initial;
    }

  }
}

.officePopUp {
  width: 80%;
  margin: auto;

  h2 {
    font-size: 22px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: $gray-hex;
    padding: 10px 20px;
    color: $secondary-hex;
  }

  ol {
    padding-left: 30px;
  }

  li {
    margin-top: 15px;
    font-size: 15px;
  }
}
