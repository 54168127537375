.viewChairmanshipPublic {
	display: flex;
	flex-wrap: wrap;
	background-color: $gray-hex;
	padding: 1.5rem 3rem;
	color: #fff;
	font-size: 1.6rem;

	& > * {
		border-left: 1px solid #fff;
		padding: 0 15px;
		margin-top: 15px;

		&:hover {
			background-color: lighten($color: $gray-hex, $amount: 10);
			cursor: pointer;
		}

		&:last-child {
			border-right: 1px solid #fff;
		}
	}

	
}

.aspirantListPublic {
	width: 100%;
	border-collapse: collapse;
	font-size: 1.5rem;
	margin-bottom: 5rem;

	tr,
	th,
	td {
		border: 1px solid $gray-hex;
		padding: 15px;
	}

	tr:hover {
		background-color: lighten($color: $gray-hex, $amount: 10);
		color: #fff;
	}

	thead {
		background-color: $gray-hex;
        color: #fff;
        
        th {
            border: 1px solid lighten($color: $gray-hex, $amount: 50);
        }

		& tr:hover {
			background-color: lighten($color: $gray-hex, $amount: 10);;
			color: #fff;
		}
	}
}

.viewChairmanshipPublic__headerPublic, .viewChairmanship__headerPublic{
	color:$gray-hex;
	font-size: 2.4rem;
	margin: 3rem 0 1.5rem;
	text-transform: uppercase;
	
	&::after {
		content: '';
		height: 1px;
		width: 100%;
		background-color: $gray-hex;
		margin-bottom: 4rem;
		margin-top: 1rem;
		display: block;
	}
}
