.mp {
padding: 6rem 0;


    &__inner {
        padding-right: 3rem;

    }

    &__box {
        display: flex;
        margin-bottom: 3rem;
        padding: 3rem;
        background-color: #fff;
    }

    &__body{

        font-size: 1.4rem;
        // font-weight: 300;
        display: flex;

    }

    &__figure {
        width: 100px;
        height: 100px;
        flex: 0 0 100px;
        margin-right: 3rem;
    }

    &__ft {
        margin-left: 3rem;
    }

    &__btn {
        margin-bottom: 10px;
        cursor: pointer;
        border: 0;

        &:focus {
            outline: none;
        }
    }

    &__img {

    }
}