.nomination {
padding:6rem;
 background-color: #f1f1f1;




 &__inner {
    //  background-color: #fff;
    border-bottom: 1px solid rgba($primary-hex, .51);
     margin-bottom: 1.5rem;

   
 }

    &__h2 {
       margin-bottom: 3rem;
       font-size: 2.2rem;
    }

    &__h3 {
        margin-bottom: .1rem;
        font-size: 1.6rem;
        text-transform: uppercase;
        width: 100%;

        @include respond(md) {
            width: 50%;
        }
    
    }

    &__links {
        padding-bottom: 3rem; 
        padding-top: 2rem;
    }

    &__links__view, &__links__download {
        display: inline-block;
        font-size: 1.4rem;
        padding: .25rem .75rem;
        border: 1px solid $gray-hex;
        text-decoration: none;

        &:hover {
            background-color: $gray-hex;
            color:#fff;
        }

        &:focus {
            outline: none;
            border: 1px solid $primary-hex;
        }
    }
    &__links__download {
        margin-left: 1rem;
    }

    
}