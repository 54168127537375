.dashboard {

width: 60%;
margin: 3rem auto ;

display: flex;
flex-wrap: wrap;
justify-content: space-between;


.box {
    flex: 0 0 30%;
    margin-bottom: 4rem;
    padding: 1rem;
    position: relative;
    box-shadow: 0 2px 4px rgba(0,0,0, .4);
    border-top-right-radius: 5%;
    border-bottom-left-radius: 5%;
}

.itembox1 {
 background-color:  #8892d6;   
}
.itembox2 {
background-color: #45bbe0;
}
.itembox3 {
background-color: #f06292;
}
.itembox4 {
background-color: #78c350;
}
.itembox5 {
background-color: rgb(247, 83, 31);
}
.itembox6 {
background-color: rgb(255, 152, 0);
}
.itembox7 {
background-color: rgb(117, 126, 2);
}
.itembox8 {
background-color: rgb(0, 132, 255);
}
.itembox9 {
background-color: rgb(245, 143, 206);
}

.right {
    display: flex;
    align-items: center;

    .para {
        margin-right: 3rem;
        color: #fff;
    }
}

.top__arrow {
    display: flex;
    align-items: center;
    color: $gray-hex;
    margin: 1.2rem 0;
}


.top__span {
    font-size: 4rem;
    color: #fff;
    margin-right: 10px;
}
.abo {
    position: absolute;
    top: 35%;
    right: 10%;
}

.top {
    h2 {
        color: #fff;
        letter-spacing: 2px;
    }
}
}