/* Color Theme Swatches in Hex */
$bg-facebook: #2A5BA2;
$bg-twitter: #05B1E7;
$bg-youtube: #F20505;

$bg-instagram-1: #A674CD;
$bg-instagram-2: #EB5270;
$bg-instagram-3: #FCCD89;

/* Color Theme Swatches in Hex */
$gray-hex: #4F4C5D;
// $gray-hex: #4F4C5D;
$gray-hex1: #4C4A59;
$primary-light-hex: #038C73;
$primary-hex: #1C796A;
$secondary-hex: #F8FBA0;
$secondary-hex1: #F2EDA0;
$off-white-hex: #F2F2F2;
$white: #fff;

/* Color Theme Swatches in RGBA */
$gray-rgba: rgba(76,74,89, 1);
$primary-light-rgba: rgba(3,140,115, 1);
$primary-rgba: rgba(28,121,106, 1);
$secondary-rgba: rgba(242,237,160, 1);
$off-white-rgba: rgba(242,242,242, 1);

/* Color Theme Swatches in HSLA */
$gray-hsla: hsla(247, 9%, 31%, 1);
$primary-light-hsla: hsla(169, 95%, 28%, 1);
$primary-hsla: hsla(170, 62%, 29%, 1);
$secondary-hsla: hsla(56, 75%, 78%, 1);
$off-white-hsla: hsla(0, 0%, 94%, 1);

/*Greey Theme Swatches in Hex*/
$gray-light-1: #f4f4f4;
$gray-light-2: #f4f2f2;
$gray-dark:#3d3d3d;
$white:#fff;

/* Color Theme Swatches in Hex */
$color-facebook: #2A5BA2;
$color-twitter: #05B1E7;
$color-youtube: #F20505;

$color-instagram-1: #A674CD;
$color-instagram-2: #EB5270;
$color-instagram-3: #FCCD89;

/* Color Theme Swatches in Hex */
$color-theme_sample-1-hex: #4C4A59;
$color-theme_sample-2-hex: #038C73;
$color-theme_sample-3-hex: #F2EDA0;
$color-theme_sample-4-hex: #1C796A;
$color-theme_sample-5-hex: #F2F2F2;

/* Color Theme Swatches in RGBA */
$color-theme_sample-1-rgba: rgba(76,74,89, 1);
$color-theme_sample-2-rgba: rgba(3,140,115, 1);
$color-theme_sample-3-rgba: rgba(242,237,160, 1);
$color-theme_sample-4-rgba: rgba(28,121,106, 1);
$color-theme_sample-5-rgba: rgba(242,242,242, 1);

/* Color Theme Swatches in HSLA */
$color-theme_sample-1-hsla: hsla(247, 9%, 31%, 1);
$color-theme_sample-2-hsla: hsla(169, 95%, 28%, 1);
$color-theme_sample-3-hsla: hsla(56, 75%, 78%, 1);
$color-theme_sample-4-hsla: hsla(170, 62%, 29%, 1);
$color-theme_sample-5-hsla: hsla(0, 0%, 94%, 1);

/*Greey Theme Swatches in Hex*/
$color-theme-grey-1-hex: #f4f4f4;
$color-threme-grey-4-hex:#3d3d3d;
$white:#fff;
