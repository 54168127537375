.principals-news {
	// margin-bottom: 15px;

	@include respond(md) {
		// margin-bottom: 45px;
	}

	&-inner {
		max-width: 140rem;
		margin: 0 auto;
		// border: 1px groove $gray-hex;
		display: flex;
		padding: 0;
		padding-top: 0;
		padding-bottom: 0;
		flex-direction: column;
		background-color: #f4f4f4;

		@include respond(lg) {
			flex-direction: row;
		}
	}

	h2 {
		background-color: $gray-hex;
		color: $secondary-hex1;
		font-size: 2.4rem;
		letter-spacing: 1px;
		padding: 18px 24px;
		text-transform: uppercase;

		@include respond(md) {
			font-size: 1.6rem;
			font-weight: 100;
			padding: 10px 24px;
		}
	}
}

.principals {
	flex: 0 0 40%;
	margin-right: 0;
	margin-bottom: 16px;

	@include respond(lg) {
		margin-right: 3rem;
		margin-bottom: 0;
	}

	&-inner {
	}
}

.media-box {
	display: flex;
	align-items: center;
	background-color: lighten($color: $gray-hex, $amount: 5);
	color: $secondary-hex1;
	cursor: pointer;

	&:nth-child(even) {
		background-color: darken($color: $gray-hex, $amount: 5);
	}
	&__img-box {
		width: 100px;
		height: 100px;
		border-radius: 100px;
		overflow: hidden;
		margin: 16px 16px;
		transition: all 0.3s ease-out;
		&:hover {
			transform: scale(1.1);
		}
	}

	&__img {
		//    moz-o-object-fit: cover ;
		object-fit: cover;
	}

	&__body {
		flex: 1;
		font-size: 2.4rem;

		@include respond(md) {
			font-size: 2rem;
		}
		@include respond(lg) {
			font-size: 1.6rem;
		}

		&:hover {
			color: lighten($color: $secondary-hex, $amount: 50);
		}
	}
}

.news {
	&-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 3rem;
		// background-color: #f2f4f4;

		@include respond(md) {
			padding: 0;
			padding-top: 3rem;
		}

		& > * {
			flex: 0 0 calc(100%);

			@include respond(md) {
				flex: 0 0 calc(100% / 2 - 1.5rem);
			}

			@include respond(xl) {
				flex: 0 0 calc(100% / 3 - 2rem);
			}
		}
	}
}

.news-box {
	margin-bottom: 2.5rem;
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);

	&:hover {
	}

	&__img-box {
		height: 220px;

		@include respond(sm) {
			// height: 220.5px;
		}
		@include respond(md) {
			// height:200px;
		}
	}

	&__img {
		object-fit: unset;
		height: 220px;
		width: 100%;
	}

	&__body {
		font-size: 2.6rem;
		padding: 0.8em;
		text-align: left;

		@include respond(md) {
			font-size: 2rem;
        }
        
        @include respond(lg) {
            font-size: 1.4rem;
        }
	}
}
