//breakpoints
//xs less than 576px <36em
//sm 576px 36em
//md 768px 48em
//lg 992px 62em
//xl greater than 1200px 75em

@mixin  respond($bk) {

    @if $bk == xs {
        @media (max-width: 36em) {
            @content
        }
    }
   
    @if $bk == sm {
        @media (min-width: 36em) {
            @content
        }
    }
    @if $bk == md {
        @media (min-width: 48em) {
            @content
        }
    }
    @if $bk == lg {
        @media (min-width: 62em) {
            @content
        }
    }
    @if $bk == xl {
        @media (min-width: 75em) {
            @content
        }
    }
    
}