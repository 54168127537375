.container {
  max-width: 140rem;
  margin: 0 auto;
  .banner {
    display: none;
  }

@include respond(md) {
    .banner {
      display: block;
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
}

.container__banner {
  background-image: none;
  @include respond(lg) {
    background-image: url(./../../Images/bannerlasiec.jpg);
    height: 150px;
    background-repeat: no-repeat;
    background-position: -100px 0px;
  }
}

.container-padding-small {
  padding: 0 1.6rem;
}
.container-padding-large {
  padding: 0 3.2rem;
}
