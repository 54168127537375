

.specialbtn {
    color: gray !important;
    text-align: center;
    border: 1px solid gray;
    padding: 5px 10px;
    width: 120px;
    border-radius: 5px;
    cursor: pointer;


    &:disabled {
        color: gray !important;
        cursor: not-allowed;
    }

    &:disabled:hover {
        color: gray !important;
    }


    &:hover {
        color: maroon !important;
    }
}

.bigger {
    width: 180px;
}

.mainH {
    display: flex;
    justify-content: space-between;
}

.addTrendingNews__group {
    margin-bottom: 1.5rem;

    input, textarea, select {
        width: 50%;
        display: block;
        padding: 10px 12px;
        border: 1px solid gray;
       

        &:focus {
            outline: none;
            border: 1px solid green;
        }

    }

    label {
        font-size: 1.6rem;
        margin-bottom: 1rem;

    }
}

.formbox {
    border: 1px solid rgb(184, 184, 184);
    padding: 3rem;
    
}

.formH2 {
    font-size: 2.2rem;
    color: #1C796A;
    font-weight: bold;
}

.formH2 {
    font-size: 1.6rem;
    color: #1C796A;
}


.bfont {
    font-size: 1.8rem;
    color: #1C796A !important;
}
.addTrendingNews__noborder {
    margin-bottom: 1.5rem;

    input {
        display: block;
    }
    label {
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
}