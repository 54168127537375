.Toastify__toast-container {
    width: 350px; 
    text-align: center;

    @include respond(sm) {
        width: 500px;
    }
}
.Toastify__toast-body {
    font-size: 1.6rem;
    padding: 1em;
   
}