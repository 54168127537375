﻿.section-gallery {

  
  
   
   
}


.gallery {
    max-width: 140rem;
    background-color: $gray-hex;
    margin: 0 auto;
    
    padding:3rem;
    padding-top: 0;
    display: flex;
    flex-direction: column;
   

    & > .heading__2 {
       
        margin:0;
        padding:0;
        
        
    }

    & > .heading__2::after {
       

        
    }

    &__inner {
        background-color: #fff;
        // padding:3rem;
        box-shadow: 0 2px 4px rgba(0,0,0, .06);
      

       
    }

    &__image {
        height: 43.7rem;
        width: 100%;

    }


    &__src {
        height: 100%;
        width: 100%;
    }


}
