.modal {
    position: fixed;
    background: rgba(255, 255,255, 1);
    width: 100%;
    z-index: 501;
    left:0;
    top:0;
    padding:3rem;
    border:1px solid #ccc;
    box-shadow: 1px 2px 2px rgba(0,0,0, .8);
    transition: all .3s ease-out;
    max-height: 100%;
    overflow: auto;

    @include respond(lg) {
        width: 60%;
        left: 20%;
    }
    

    h1 {
        font-size: 5rem;
        margin-bottom: 3rem;
        margin-top:0;
        text-align: center;
        letter-spacing: 2px;

        @include respond(sm){
            font-size: 3rem;
        }

    }

    p{
        font-size: 2rem;
        letter-spacing: .1rem;
        line-height: 1.6;

        @include respond(md){
            font-size: 1.6rem;
        }

    }

    p:not(:last-of-type) {
        margin-bottom: 3rem;


        @include respond(sm) {
            margin-bottom: 1.5rem;
        }
    }


    &__close__icon{
        position: absolute;
        right:5px;
        top: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        @include respond(md) {
            right:20px;
            top: 10px;
        }
    }
}

.popComposition {

   
    &__heading {
        font-size: 2.8rem;
        line-height: 1;
        margin-bottom: 3rem;

        @include respond(sm) {
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }
    }

    @include respond(lg) {
        max-width: 80%;
        margin-left: 10%;
    }
}