
.footer {
   
   
 
   


 


    &__inner {
        background-color: #333;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        color: #e5e5e5;
        max-width: 140rem;
 
        margin: 0 auto;

        & > * + * {
            margin-top: 6rem;
        }
        
       
        @include respond(md) {
            flex-direction: row;
            align-items: flex-start;
            

        & > * {
            flex: 1;
        }

        & > * + * {
            margin-left: 6rem;
            margin-top: 0;
        }

        }

        
    }



    & h4 {
        font-size: 2.8rem;
        text-transform: uppercase;

        @include respond(md) {
            font-size: 1.4rem;
        }
    }


    &__newsletter {
    }

    &__lagosHistory {




        & > p {
            font-size: 2.2rem;
            margin: 1.8rem 0;

            @include respond(md) {
                font-size: 1.4rem;
            }
        }


        & > a {
            background-color: #000;
            color: #fff !important;
            padding: .8em 1em;
            text-decoration: none;
            font-size: 2.2rem;

            @include respond(md) {
                font-size: 1.4rem;
            }
          
        }
    }


    &__lagosGovernment {

        & > p {
            font-size: 2.2rem;
            margin: 1.8rem 0;

            @include respond(md) {
                font-size: 1.4rem;
            }
        }
    }

    &__calender {

        & > h4 {

            margin-bottom: 1.8rem;
        }
    }
}




.ui-datepicker {
    min-width:240px;
}

.footerform {


    &__group {
        margin: 1.8rem 0;
        display: flex;
        flex-direction: column;



        & > label {
            font-size: 2.2rem;
            margin-bottom: 1rem;

            @include respond(md) {
                font-size: 1.4rem;
            }
        }



        & > input {
            border: 0;
            padding: .8rem 1rem;
        }
    }



    &__btn {
        background-color: #000;
        color: currentColor;
        padding: .8em 1em;
        text-decoration: none;
        font-size: 2.2rem;
        border: 0;

        @include respond(md) {
            font-size: 1.4rem;
        }
   
    }

    
}


.container--footer {
    max-width: 140rem;
    margin:0 auto ;
    color: black;
    font-size:2.2rem;
    text-align:center;
    padding-bottom:0;
    background-color: #f2f4f4;
    padding: 12px 0;

    @include respond(md) {
        font-size:1.4rem;
    }
}

