.adminNavContainer{
    position: relative;


    &__btnm {
    cursor: pointer;
    background-color: #038c73;
    color: #fff;
    padding: 10px 20px;
    font-size: 1.5rem;
    letter-spacing: 2px;
    border: none;

    &:focus {
        outline: none;
    }
    }
}

.adminNav{
max-width: 140rem;
margin: auto;
// min-height: 100vh;
background-color: #f1f1f1;
box-shadow: 0 2px 2px 4px rgba($primary-hex,.2);
margin-bottom: 6rem;



font-size: 15px;

a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 3px 7px;


    &:hover {
        color: $secondary-hex;
    }

    &.active {
        background-color: lighten($color:$primary-hex, $amount: 30);
        margin-left: 5px;
    }
}

ul {
    list-style: none;
}

li > ul {
    padding-left: 1.2rem;
    list-style: none;
}


&__inner {
    // display: flex;
}

}

.sidebarNav {
    background-color:$primary-hex;
    // // flex: 0 0 0px;
    // min-height: 100vh;
    // padding:3rem;
    // margin-right: 3rem;
    // letter-spacing: 1px;
    // transition: all .5s ease-out;
    position:fixed;
    z-index: 1;
    height: 100%;
    left:0;
    top:0;
    padding: 0;
    margin-top: 70px;
    width: 0;
    overflow-x: hidden; 
    transition: 0.5s ease-out;


    .closebtn {
        position: absolute;
        top: 30px;
        right:10px;
        font-size: 36px;
        margin-left: 50px;
        color: #fff;
        background:none;
        border:none;
        outline: none;
        cursor: pointer;
      }
}


.main {
    //background-color: #fff;
    flex: 1;
    margin: 6rem 0;
    padding:3rem;
   


    &__head {
        position: absolute;
        top: 0;
        right:13%;
        background-color: $primary-hex ;
        color: $white;
        padding: 1rem 2rem;
        font-size: 1.8rem;
        letter-spacing: 2px;
    }
}

.dashboard {
    
}

.pressrelease {
   
}

.sec {
    margin-bottom: 1.5rem;
}

.expand {
    width: 250px;
    // padding: 6rem 5rem;
}

.mrtop {
    margin-top: 3rem;
}