@mixin hover() {
    &:hover { @content; }
  }
  
  @mixin hover-focus() {
    &:hover,
    &:focus {
      @content;
    }
  }
  
  @mixin plain-hover-focus() {
    &,
    &:hover,
    &:focus {
      @content;
    }
  }
  
  @mixin hover-focus-active() {
    &:hover,
    &:focus,
    &:active {
      @content;
    }
  }
  
// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                false !default;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;


@mixin clearfix() {
    &::after {
      display: block;
      clear: both;
      content: "";
    }
  }
  

// Carousel

$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;

$carousel-control-icon-width:        20px !default;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") !default;

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)




//transition

@mixin transition($transition...) {
    @if length($transition) == 0 {
      $transition: $transition-base;
    }
  
    @if length($transition) > 1 {
      @each $value in $transition {
        @if $value == null or $value == none {
          @warn "The keyword 'none' or 'null' must be used as a single argument.";
        }
      }
    }
  
    @if $enable-transitions {
      @if nth($transition, 1) != null {
        transition: $transition;
      }
  
      @if $enable-prefers-reduced-motion-media-query and nth($transition, 1) != null and nth($transition, 1) != none {
        @media (prefers-reduced-motion: reduce) {
          transition: none;
        }
      }
    }
  }

  
// Notes on the classes:
//
// 1. .carousel.pointer-event should ideally be pan-y (to allow for users to scroll vertically)
//    even when their scroll action started on a carousel, but for compatibility (with Firefox)
//    we're preventing all actions instead
// 2. The .carousel-item-left and .carousel-item-right is used to indicate where
//    the active slide is heading.
// 3. .active.carousel-item is the current slide.
// 4. .active.carousel-item-left and .active.carousel-item-right is the current
//    slide in its in-transition state. Only one of these occurs at a time.
// 5. .carousel-item-next.carousel-item-left and .carousel-item-prev.carousel-item-right
//    is the upcoming slide in transition.

.carousel {
    position: relative;
    display: none;
   @include respond(sm) {
     display: block;
   }
  }
  
  .carousel.pointer-event {
    touch-action: pan-y;
  }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    // height: 100%;
    overflow: hidden;
    @include clearfix();
  }
  
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    @include transition($carousel-transition);
  }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }
  
  .carousel-item-next:not(.carousel-item-left),
  .active.carousel-item-right {
    transform: translateX(100%);
  }
  
  .carousel-item-prev:not(.carousel-item-right),
  .active.carousel-item-left {
    transform: translateX(-100%);
  }
  
  
  //
  // Alternate transitions
  //
  
  .carousel-fade {
    .carousel-item {
      opacity: 0;
      transition-property: opacity;
      transform: none;
    }
  
    .carousel-item.active,
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      z-index: 1;
      opacity: 1;
    }
  
    .active.carousel-item-left,
    .active.carousel-item-right {
      z-index: 0;
      opacity: 0;
      @include transition(opacity 0s $carousel-transition-duration);
    }
  }
  
  
  //
  // Left/right controls for nav
  //
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    // Use flex for alignment (1-3)
    display: flex; // 1. allow flex styles
    align-items: center; // 2. vertically center contents
    justify-content: center; // 3. horizontally center contents
    width: $carousel-control-width;
    color: $carousel-control-color;
    text-align: center;
    opacity: $carousel-control-opacity;
    @include transition($carousel-control-transition);
  
    // Hover/focus state
    @include hover-focus() {
      color: $carousel-control-color;
      text-decoration: none;
      outline: 0;
      opacity: $carousel-control-hover-opacity;
    }
  }
  .carousel-control-prev {
    left: 0;
    @if $enable-gradients {
      background-image: linear-gradient(90deg, rgba($black, .25), rgba($black, .001));
    }
  }
  .carousel-control-next {
    right: 0;
    @if $enable-gradients {
      background-image: linear-gradient(270deg, rgba($black, .25), rgba($black, .001));
    }
  }
  
  // Icons for within
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: $carousel-control-icon-width;
    height: $carousel-control-icon-width;
    background: no-repeat 50% / 100% 100%;
  }
  .carousel-control-prev-icon {
    background-image: escape-svg($carousel-control-prev-icon-bg);
  }
  .carousel-control-next-icon {
    background-image: escape-svg($carousel-control-next-icon-bg);
  }
  
  
  // Optional indicator pips
  //
  // Add an ordered list with the following class and add a list item for each
  // slide your carousel holds.
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0; // override <ol> default
    // Use the .carousel-control's width as margin so we don't overlay those
    margin-right: $carousel-control-width;
    margin-left: $carousel-control-width;
    list-style: none;
  
    li {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: $carousel-indicator-width;
      height: $carousel-indicator-height;
      margin-right: $carousel-indicator-spacer;
      margin-left: $carousel-indicator-spacer;
      text-indent: -999px;
      cursor: pointer;
      background-color: $carousel-indicator-active-bg;
      background-clip: padding-box;
      // Use transparent borders to increase the hit area by 10px on top and bottom.
      border-top: $carousel-indicator-hit-area-height solid transparent;
      border-bottom: $carousel-indicator-hit-area-height solid transparent;
      opacity: .5;
      @include transition($carousel-indicator-transition);
    }
  
    .active {
      opacity: 1;
    }
  }
  
  
  // Optional captions
  //
  //
  
  .carousel-caption {
    position: absolute;
    right: calc((100% - $carousel-caption-width)/2);
    bottom: 20px;
    left: calc((100% - $carousel-caption-width)/ 2);
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $carousel-caption-color;
    text-align: center;
  }
  