.loginPage {

    
    position: relative;
    // padding: 3rem 0;
    margin-bottom: 1rem;

    &__header {
        height: 700px;
        width: 140rem;
        margin: auto;
        // margin-left: -3rem;
        background: url(./../../Images/lasiecbuilding.gif);
    }

    &__panel {
        position: absolute;
        min-width: 15rem;
        width: 80%;


        background-color: rgba($white, .8);
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        padding: 3rem 6rem;
        border-radius: 3px;
        box-shadow: 0 2px 4px 4px rgba($primary-hex, .8);

        @include respond(md) {
            width: 30%;
        }

    }


    &__form__group{
        margin-bottom: 2rem;
    }

    &__form__control{
        display: block;
        width: 100%;
        padding: 1rem 1.3rem;
        border: 1px solid $gray-hex;
        border-radius: 3px;
        font-size: 2rem;
        color: $primary-hex;

        &:focus {
            outline: none;
            border: 1px solid lighten($color:$primary-hex, $amount: 20)
        }

        @include respond(md) {
            font-size: 1.6rem;
        }
    }
    &__form__label{
        display: inline-block;
        font-size: 2rem;
        margin-bottom: 1rem;
        letter-spacing: 1px;
        font-weight: 700;


        @include respond(md) {
            font-size: 1.6rem;
        }
    }

    &__btn {
        width: 70%;
        padding: 1.2rem 1.5rem;
        font-size: 2rem;
        text-transform: uppercase;
        margin-left: 15%;
        background-color: $primary-hex;
        border: 0;
        color: $white;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }

        @include respond(md) {
            font-size: 1.6rem;
        }
    }

    &__error {
        color: red;
        font-size: 1.4rem;
        text-align: center;
    }
}