
.section-director {
padding: 6rem 3rem;
background-color: #f1f1f1;


h2 {
    font-size: 3.2rem;
    margin-bottom: 4.5rem;
    letter-spacing: 2px;

    &::after {
        content: '';
        display: block;
        background-color: rgba($gray-hex, .25);
        margin-top: 1rem;
        height: 1px;
        width: 90%;
    }
}
}



.headDepartment {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    @include respond(sm){
        flex-wrap: wrap;
        justify-content: space-around;
        flex-direction: row;
    }

    @include respond(lg) {
    }
}

.headDepartment__box {
    width: 100%;
    margin-bottom: 3rem;
    


    .pic {

        width: 30rem;
        height: 30rem;
        border-top-right-radius:50%;
        border-bottom-right-radius: 50%;
        border-top-left-radius:50%;
        overflow: hidden;
        border: 5px solid #fff;
    }

    @include respond(sm) {
        width: 40%;
    }

    @include respond(lg) {
        width: 30%;
    }

}
.headDepartment__body {

    width: 100%;
    h3 {
        font-size: 20px;
        letter-spacing: 1px;
        margin-top: 1.5rem;
    }

    h4 {
        font-size: 16px;
        font-weight: 100;
        margin-top: 1.5rem;
        letter-spacing: 2px;

    }

    p {
        font-size: 14px;
        margin-top: 1.5rem;
        width: 100%;
        text-align: justify;
        word-spacing: 2px;
    }
}


















//   .mr {
//    margin-right: 45px;
//   }
  
//   .director {
  
//   padding: 60px 30px;
//   width: 40%;
//   margin:auto;
  
//   }
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     display: block;
//   }
  
//   .director__finance {
//     display:flex;
  
//     justify-content: space-between;
//     padding: 30px 55px;
  
//     border-bottom: 1px solid #ddd;
//   }
//   .director__admin {
//     display:flex;
  
//     padding: 30px 55px;
//   }
  
//   .director__img-box {
  
//     flex: 0 0 150px;
//    height: 150px;
//    background-color: violet;
//    border-radius: 50%;
//    overflow: hidden;
//    border: 3px solid #ccc;
   
//   }
  
//   .director__body {
//     flex: 1;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//   }
  
//   .director__img-box--order{
    
//   }
  
//   h3 {
//    letter-spacing: 1px;
//    font-weight: 700;
//   }
  
//   h4 {
//    font-weight: 300;
//    font-size: 13px;
//   }
  
//   p {
//     font-size: 13px;
//     text-align: justify;
//     word-spacing: 1px;
//   }


//   .conDirector {
//     width: 50%;
//     margin:auto;
//     display: flex;
//     margin-bottom: 60px;
// }

// .pic {

//     width: 150px;
//     height: 150px;
//     border-top-right-radius:50%;
//     border-bottom-right-radius: 50%;
//     border-top-left-radius:50%;
//     overflow: hidden;
//     border: 5px solid #f1f1f1;

// }

// .box + .box {

//     margin-left: 60px;
    
// }

// img {
//     width: 100%;
//     height: auto;
// }

// p {
//     width: 180px;
//     text-align: justify;
//     margin-top: 15px;
//     padding-right: 15px;
// }
  
  