.voting {
    background-color: #f1f1f1
}
.votingGuideline  {
    width: 90%;
    padding: 6rem 3rem;
    margin: 0 auto;

    font-size: 1.6rem;

    @include respond(lg) {
        width: 60%;
    }

    .h2 {
        text-transform: capitalize;
        margin-bottom: 2rem;

        &::after {
           content: '';
           display: block;
            height: 1px;
            width: 200px;
            background-color: $gray-hex;
            margin-top: 1.5rem;
        }
    }
    p {
        letter-spacing: 1px;
        text-align: justify;

    }

 & > p + p {
     margin-top: 1.5rem;
 }
}



.votingProcedure {

    width: 90%;
    padding: 6rem 3rem;
    margin: 0 auto;

    font-size: 1.6rem;

    @include respond(lg) {
        width: 60%;
    }

    .h2 {
        text-transform: capitalize;
        margin-bottom: 2rem;

        &::after {
           content: '';
           display: block;
            height: 1px;
            width: 200px;
            background-color: $gray-hex;
            margin-top: 1.5rem;
        }
    }
    p {
        letter-spacing: 1px;
        text-align: justify;

    }

 & > p + p {
     margin-top: 1.5rem;
 }
}