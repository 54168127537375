.backdrop {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .5);
    z-index: 500;
    backdrop-filter:blur(2px) brightness(80%);
}
