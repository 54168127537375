.observer__container {
  background-color: #f1f1f1;
  padding: 6rem 3rem;

  h2 {
    margin-bottom: 3rem;
    font-size: 2rem;
    width: 70%;
    margin: 0 auto 3rem auto;
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin: auto;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 70%;
    font-size: 1.6rem;
  }

  .alert__primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }

  .alert__success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}

.observer {
  width: 100%;
  margin: 0 auto;

  @include respond(lg) {
    width: 70%;
  }

  .file-block {
    display: block;
  }

  &__container {
  }

  .invalid {
    background-color: rgb(248, 154, 154);
    border: 1px solid red;
  }

  &__form__group {
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    letter-spacing: 2px;

    .observer__form__textarea {
    }
  }

  .fmx {
    flex-direction: column;
  }
  &__form__control {
    padding: 1.5rem 2rem;
    width: 48%;
    font-size: 16px;
    letter-spacing: 2px;

    &:focus {
      outline: none;
    }
  }

  .sm {
    margin-bottom: 1.5rem;
  }

  .ss {
    width: 100%;
  }

  .kk {
    width: 100% !important;
  }

  .observer__form__textarea {
    width: 100%;
    padding: 1.5rem 2rem;
    font-size: 16px;
    letter-spacing: 2px;

    &:focus {
      outline: none;
    }
  }

  .observer__form__groupd {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
    font-size: 1.6rem;
    letter-spacing: 2px;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  .bm {
    margin-right: 1.5rem;
  }

  .submit {
    padding: 1.5rem 2rem;
    width: 40%;
    margin: 0 auto;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    font-size: 1.6rem;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
    }

    &:disabled {
      background-color: #ccc;
      color: $gray-hex;
      border: 0;
      cursor: not-allowed;
    }
  }
}
