.adminObservers {
    position: relative;

    &__h2 {
        margin-bottom: 8rem;
    }

    &__input {
        margin-bottom: 3rem;
        padding: 1rem 1.2rem;
        outline: none;
        border: 1px solid $gray-hex;
        width: 50%;
        position: absolute;
        top:0;
        right: 0;
        display: flex;
        justify-content: space-between;

        @include respond(md) {
            width: 35%;
        }

        &__input {
            padding: .5rem 1rem;
            width: 60%;
            

            &:focus {
                outline: none;
            }
        }


        &__select {
            padding: .5rem 1rem;
            margin-left: 2rem;
            // width: calc(50% - 2rem);

            &:focus {
                outline: none;
            }
        }
    }
    &__table {
        border-collapse: collapse;
        margin-top: 10px;
        width: 100%;
        th, td, tr {
            padding: 10px;
            border: 1px solid $gray-hex;
             font-size: 1.4rem;
        
        }
    }

    .btn {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1.6rem;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .btn-primary{
        color: #fff;
        background-color:$primary-hex;
        border-color: $primary-hex;
    }

    .badge {
        display: inline-block;
        padding:.25em .5em;
        font-size: 90%;
        margin-left: 20px;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 2px;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    }
    .badge-light{
        color: #212529;
        background-color: #f8f9fa;
    }

    .mgbtm {
        margin-bottom: 3rem;
        padding: 15px;
    }

    .boxes {
        display: flex;
        position: absolute;
        right: 0;
        top:70px;
        justify-content: center;
        align-items: center;

        & > * {
            padding: .5rem 1rem;
        }

        & > * + * {
            margin-left: 20px;
        }


        .box1 {
          background-color: #f1f1f1 ;
         border: 1px solid #000;
        }
        .box2 {
       
           background-color:#D4FAE6 ;
           border: 1px solid #000;

        }
        .box3 {
            background-color: #1BFA6F;
            border: 1px solid #000;
        }
        .box4 {
            background-color:#FF9190;
            border: 1px solid #000;
        }
        .box5 {
            background-color: #C4C4C4;
            border: 1px solid #000;
        }
        .box6 {
            background-color: #1C796A;
            color: white;
            border: 1px solid #000;
        }
    }

}

.magic {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    


    &__label {

    }

    &__select {
        width: 50%;
        padding: .5rem 1rem;
    }

    &__btn {
        width: 25%;
        padding: .5rem 1rem;
    }

}

.spbtn {
    padding: .5rem 1.75rem;
    background-color: $primary-hex;
    color: #fff;
    border: none;
    font-size: 1.4rem;
    display: inline-block !important;
   

    &:focus {
        outline: none;
    }
}

.adminObserversinput {

    &:focus {
        outline: none;
    }
}

.datefilterOption {
    margin-bottom: 15px;
    flex-direction: column;
    display: flex;
    
    @include respond(md) {
        justify-content: space-between;
        flex-direction: row;
        
    }

    input {
        width: 100%;
        padding: 7px 15px;
        
        &:focus {
            outline: none;
        }

    }

}

.dropdownOptions{
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond(sm) {
        flex-direction: row;
    }
    input, select {
        width: 100%;
        padding: 7px 15px;
        &:focus {
            outline: none;
        }
    }

}
.searchandPrintOptions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 60px;
    @include respond(md) {
       flex-direction: row;
    }
    input, select {
        width: 100%;
        padding: 7px 15px;
        @include respond(xs) {
            margin-bottom: 15px;
        }
        &:focus {
            outline: none;
        }
    }
}
.pastExperienceOptions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 15px;

    @include respond(md) {
        flex-direction: row;
    }

    input, select {
        width: 100%;
        padding: 7px 15px;
        &:focus {
            outline: none;
        }
    }
}

.w850 {
    width: 100%;
    @include respond(md) {
        width: 40%;
    }
}

.w820 {
 width: 100%;
    @include respond(md) {
           width: 20%;
    }
}

.labelgreen {
    color: #1C796A;
    font-weight: bold;
}
.sendEmailSms {
    display: flex;
    justify-content: space-between;
}