.election-wards {

    background-color: #f1f1f1;
    padding: 6rem 3rem;
    color: $gray-hex;

    h2 {
        font-size: 2.4rem;
        margin-bottom: 1.5rem;
        color:$gray-hex;
        text-transform: uppercase;
        letter-spacing: 2px;
        width: 90%;
        margin:auto;
        margin-bottom: 3rem;
    }
    h3 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        color:$gray-hex;
        text-transform: capitalize;
        letter-spacing: 2px;
        width: 90%;
        margin:auto;
        margin-bottom: 3rem;
    }




 table, th, td, tr {
    border: 1px solid $gray-hex;
     font-size: 1.6rem;

}


th {
    padding: 10px;
}
thead {
    font-size: 2rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    
}
tr:hover {background-color: #fff; color: #111; font-weight: 600;}

td {

    text-align: center;
    padding: 10px;

    table.db {
        width: 100%;
        border-collapse: collapse;
        border-bottom: none;

       td {
           
        }
    }
}

 td.ddb{
    text-align: left;
    padding:0;


    ul.db {
        list-style:none;

        li {
            padding: 10px 20px;
        }

        li + li {
            border-top: 1px solid $gray-hex;;
        }
    }

  
}


table.db {
    
    border: none;
    padding: 0;

    tr {
        border: none;
    }
}

.tbElection{
    width: 90%;
    border-collapse: collapse;
    margin: auto;


}



}