﻿.section-glance {
	// margin-bottom: 3rem;

	@include respond(md) {
		// margin-bottom: 4.8rem;
	}
}
.glance {
	&__inner {
		max-width: 140rem;
		padding: 0;
		margin: 0 auto;
		padding: 3rem;
		padding-bottom: 0;
		background-color: $gray-hex;
	}

	&__box {
		// max-width: 140rem;
		// margin: 0 auto;

		display: flex;
		flex-direction: column;

		@include respond(md) {
			flex-direction: row;
		}
	}

	&__shell {
		display: flex;
		align-items: center;
		margin-top: 3rem;

		& > p {
			font-size: 2.4rem;
			text-align: justify;

			@include respond(md) {
				font-size: 1.6rem;
			}
			span {
				display: block;
			}
		}
	}

	&__image {
		flex: 1;
		background-color: #fff;
		padding: 1.5rem;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);

		@include respond(md) {
			padding: 3rem;
		}

		& > p {
			font-size: 2.4rem;
			text-align: justify;

			@include respond(md) {
				font-size: 1.6rem;
			}
		}
	}

	&__src {
		height: 75px;
		width: 75px;
		border-radius: 10rem;
		margin-right: 3rem;

		@include respond(sm) {
		}
	}

	&__story {
		flex: 0 0 60%;
		background-color: $white;
		margin-top: 3rem;
		padding: 1.5rem;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
		font-size: 2.4rem;

		@include respond(md) {
			margin-top: 0;
			padding: 3rem;
			margin-left: 3rem;
			font-size: 1.6rem;
		}
	}

	&__story > p + p {
		margin-top: 3rem;
	}
	&__story > p {
		text-align: justify;
	}
}

.heading__2 {
	font-size: 3.5rem;
	margin-bottom: 3rem;

	@include respond(md) {
		font-size: 2.5rem;
	}
	@include respond(xl) {
		margin-bottom: 2rem;
	}
	&--light {
		color: rgba(0, 0, 0, 0.5);
	}

	&--dark {
		color: $color-theme_sample-1-hex;
	}

	&::after {
	}
}

.section-composition {
	background-image: url(../../Images/Gallery/shell/pic4.jpg);
	background-position: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: -4px;
}

//composition
.composition {
	border-top: 3px solid $gray-hex;
	// transform:skewY(-18deg);
	// background-color: lighten($gray-light-2, $amount: 2);

	background-color: rgba($secondary-hex, 0.9);
	padding: 0 3rem;

	@include respond(md) {
		padding: 0;
	}

	// @include respond(md) {
	//     padding-top:30px;
	//     // margin-top: -470px;
	//     // height: 800px;
	//     // padding-top:200px;
	// }
	// @include respond(xl) {
	//     // margin-top: -307px;
	//     // height: 800px;
	//     // padding-top:200px;
	// }

	&__inner {
		// margin-bottom: 6rem;
		// padding: 6rem 3rem;
		// transform:skewY(18deg);
		max-width: 400px;
		// box-shadow: 0 2px 4px rgba(0,0,0,.25);
		margin: 0 auto;
        padding: 6rem 0;
        padding-bottom: 8rem;
		@include respond(md) {
			// padding: 60px;
			// margin: -4rem  auto;
			max-width: 80%;
		}
		@include respond(xl) {
			// padding: 10rem 15rem;
			// padding: 10rem 0rem;
			max-width: 60%;
		}

		& > .heading__2 {
			font-size: 5rem;
			font-weight: 300;
			margin-bottom: 0;
			color: maroon;


			
		}
		& > .heading__2::after {
			background-color: maroon;
			content: '';
			display: block;
			height: 2px;
			width: 200px;
            margin: auto;
            margin-top: .2rem;
            margin-bottom: 3rem;
		}

		& > p {
			font-size: 2.8rem;
			color: #222;
			word-spacing: 1px;
			letter-spacing: 0.8px;
			text-align: justify;
			font-weight: 400;
			text-align: justify;

			@include respond(md) {
				font-size: 2rem;
				// text-align: center;
			}
			@include respond(xl) {
			}
		}
	}
}

.compose {
	display: block;
	margin-top: 1.5em;
	text-align: center;
}

.btn-compose {
	padding: 0.75em 3.5em;
	font-size: 1.75rem;
	background-color: $gray-hex;
	border: 0;
	color: $secondary-hex;
	box-shadow: 2px 4px rgba($gray-hex, 0.2);
	cursor: pointer;
}
