.public__election__container{
    background: linear-gradient(#f3f3f3, #f1f1f1);
    padding: 2% 10%;
    h1{
        font-size: 2.8rem;
        margin-bottom: 2rem;
    }

    color: $gray-hex;
}

.public__election_result{
    
}

.public__electionResult__Landing__page {

    h2{
        font-size: 2.4rem;
        margin-bottom: 1rem;
    }

    h3 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    p{
        font-size: 1.6rem;
        margin-bottom: 1rem;
        width: 80%;
    }

}

.public__electionResult__Landing__page__p{
 
}

.public__electionResult__header__h3{


}

.public__election__hr{
    margin-bottom: 3rem;
    background-color: $gray-hex;
}

.public__electionResult__link{
    text-decoration: none;
    cursor: pointer;
    padding: 1.5rem 3rem;
    color: $secondary-hex;
    background-color: $gray-hex;
    margin: 3rem 0;
    display: inline-block;

    &:hover {
        background-color: transparent;
        border: 1px solid $gray-hex;
        color: $gray-hex;
    }

    &:focus {
        outline: none;
    }
}

