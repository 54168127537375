.section-members {
    padding: 6rem 3rem;
    background-color: #f1f1f1;
  
    
    
    h2 {
        font-size: 3.2rem;
        margin-bottom: 4.5rem;
        position: relative;
        letter-spacing: 2px;
        
    
        &::after {
            content: '';
            display: block;
            background-color: rgba($gray-hex, .25);
            margin-top: 1rem;
            height: 1px;
            width: 90%;
        }
    }

}

    .pastmembers {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba($gray-hex, .25);
        margin: 0 auto 3rem auto;
        border-radius: 5px;

        @include respond(lg) {
            width: 80%;
        }

        .sp {
            font-weight: 700;
        }

        h3 {
            background-color: rgba($gray-hex, 1);
            color:$secondary-hex;
            font-size: 2rem;
            letter-spacing: 2px;
            padding: 1rem 2rem;
            border-radius: 5px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            text-align: center;
        }

        ul {
            width: 95%;
            margin: auto;
            list-style: none;
            padding: 3rem 0;

            
        @include respond(lg) {
            width: 80%;
        }
        }

        li {
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            letter-spacing: 2px;
            color: $gray-hex;
        }

        li + li {
            margin-top: 1.5rem;
        }
    }