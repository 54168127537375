.faq {
background-color: #f1f1f1;
padding: 3rem 6rem;
color: $gray-hex;

&__h2 {
 font-size: 2.2rem;
 letter-spacing: 1px;

}

&__p {
    width: 100%;
    font-size: 1.5rem;
    color: $gray-hex;
        @include respond(md) {
            width: 70%;
        }
}

overflow: auto;
    &__inner {
        width: 100%;
        // margin: 6rem auto;
        background-color: #fff;
        padding: 3rem;
        // border: 1px solid $gray-hex;
        border-left: 2px solid $gray-hex;
        margin-top: 3rem;
        box-shadow: 0 2px 4px rgba($gray-hex, .125);
        
    }

    /* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    font-size: 1.6rem;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    letter-spacing: 1px;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active, .accordion:hover {
    background-color: #ccc;
  }
  
  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    font-size: 1.5rem;
    transition: max-height 0.3s ease-out;
    border-left: 2px inset $gray-hex;
    border-right: 2px ridge $gray-hex;
    box-sizing: border-box;
    margin: 0 1px;
  }

  .accordion:after {
    content: '\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 10px;
    color: #777;
    float: right;
    margin-left: 5px;
  }
  
  .active:after {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
  }


}