.social {
  display: flex;
  align-items: center;
}
.social__facebook {
	margin-top: 5px;
	background-color: $bg-facebook;
	display: block;
	margin-right: 5px;
	padding: 1px 5px;
}
.social__twitter {
	background-color: $bg-twitter;
	display: block;
	margin-right: 5px;
	margin-top: 5px;
	padding: 1px 5px;
}
.social__youtube {
	background-color: $bg-youtube;
	display: block;
	margin-top: 5px;
	padding: 1px 5px;
}

.social__instagram {
  width: 100px;
  height: 50px;
  padding: 3px 5px;
  padding-bottom: 6px;
  color: #fff;
	background: #f09433;
	background: -moz-linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
	background: -webkit-linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
	background: linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
