.electionResult__Landing__page{
    min-height: 40vh;

    p {
        width: 70%;
    }

    .electionResult__header {
        color:  $primary-light-hex;
        letter-spacing: 1px;
    }
    .electionResult__header__h3 {
        color:  $primary-light-hex;
        letter-spacing: 1px;
    }

    .electionResult__Landing__page__p{
        margin-bottom: 3rem;
    }
}

.electionResult__link{
    display: inline-block !important;
    background-color:  $primary-light-hex;
    margin: 15px;
    padding: 15px;
}