// .home {
//     position: relative;
//     // height: 420px;
//     overflow: hidden;
//     background-image: url(./../../Images/carousel/1.jpg);
//     background-repeat: no-repeat;
//     background-size: cover;
//     object-fit: cover;
//     @include respond(sm) {
//         // height:450px;
        
//     }
//     @include respond(md){
//         // height: 550px;
//         overflow: hidden;
//     }
 
//     @include respond(xl) {
//         // height: 560px;
//         overflow: visible;
//         // background-image: none;
//     }
// }


// .header {
//     background-image: linear-gradient(to bottom,  rgba($gray-hex, 1) 0%, rgba($gray-hex, .8) 35%,  rgba($gray-hex, .7) 50%, rgba($gray-hex, .2) 100%);
//     // background-image: linear-gradient(to bottom, rgba($primary-hex, .8), rgba($primary-hex, .8));
//     // background-image: linear-gradient(to bottom, rgba($secondary-hex, .3), rgba($secondary-hex, .3));
//     //  background-image: linear-gradient(to bottom, rgba(76,74,89, .8), rgba(76,74,89, .8));
//     max-width: 100vw;
//     position: absolute;
//     top:0;
//     left:0;
//     right:0;
//     bottom:0;
//     height: 100%;
  
    
//     &__inner {
//         max-width: 114rem;
//         margin: 0 auto;
//         position: relative;
//         height: 100%;
//         overflow:hidden;
        
//     }

//         &__img--boss {
            
//            display: none;

//             @include respond(sm) {
//                 display: block;
//                 position: absolute;
//                 right: 1rem;
//                 top: 17%;
//                 overflow: auto;
//                 max-width: 40rem;
//                 max-height: 40rem;
//                 overflow: hidden;
//             }
//             @include respond (sm) {
//                 top: 22%;
//             }
//         }
    

//     &__content {
//         position: absolute;
//         color: $secondary-hex;
//         top: 10rem;
//         left: 0;
//         padding-left: 2rem;
//         padding-right: 2rem;
//         width: 100%;

//         @include respond(sm) {
//             top: 20rem;  
//             padding-left: 6rem;
//         }
//     }



// .heading__1 {
//     font-size: 5.3rem;
//     font-weight: 900;
//     line-height: 4rem;
//     letter-spacing: 2.52rem;

//     @include respond(md) {
//         font-size: 4.5rem;
//         line-height: 3rem;
//     }

//  &--break {
//         font-size: 3.2rem;
//         letter-spacing: .17rem;
//         word-spacing: .2rem;

//         @include respond(md) {
//             font-size:  2.75rem;
//             font-weight: 900;
           
//         }
//     }

// &::after {
//         content: "";
//         display: block;
//         height: 2px;
//         width: 10rem;
//         background-color: $white;
//         margin-top:2.5rem;

//         @include respond(md){
//             margin-top:1.5rem;
//         }
        
//     }

//     @include respond(sm){
//         font-size: 7.5rem;
//         font-weight: 700;
//         line-height: 3rem;
//         letter-spacing: 2rem;
    
//      &--break {
//             font-size: 3.5rem;
//             letter-spacing: .17rem;
//             word-spacing: .1rem;
//         }
    
//     &::after {
//             content: "";
//             display: block;
//             height: 2px;
//             width: 10rem;
//             background-color: $white;
//             margin-top:1.5rem;
            
//         }
//     }

   
// }


// .paragraph {
//     font-size: 2.6rem;
//     letter-spacing: .2rem;
//     color: $secondary-hex;
//     margin-top: 3rem;
//     width: 70rem;
//     line-height: 1.3;
//     width: 100%;

//     @include respond(sm) {
//         width: 60rem;
//     }

//     @include respond(md) {
//         font-size: 1.6rem;
//         letter-spacing: .2rem;
//         color: $secondary-hex;
//         margin-top: 3rem;
//         width: 70rem;
//         line-height: 1.6;
//     }
//     @include respond(xl) {
//         font-size: 1.8rem;
//         font-weight: 700;
        
//     }
    
// }
// .paragraph__readmore  {
  
//         text-decoration: none;
//         color: $secondary-hex;
//         background-color: rgba($gray-hex, .8);
//         cursor: pointer;
//         padding: .5em 2em;
//         border:none;
//         display: block;
//         margin-top: 2em;
        

        
//     }
// }


// .section-newsletters {
//     position:absolute;
//     bottom:0;
//     left: 0;
//     right: 0;
//     // line-height: 6rem;
//     padding: 3rem 3rem;
//     width: 100%;
//     background-color:darken($gray-hex, $amount: 0) ;
//     // border-bottom: 2px solid $secondary-hex;
//     box-shadow: 0 2px 4px rgba(0,0,0,.15);

//     @include respond(sm){
//         padding: 2rem 3rem;
//     }
//     @include respond(md){
//         padding: 5rem 3rem;
//         bottom:0;
//     }
//     @include respond(xl) {
//         bottom: -6rem;
//         padding:3rem 0;
//         width: 100%;
//     }

// } 

// .newsletters {

//     max-width: 576px;
//     margin: 0 auto;
//     @include respond(sm) {
//         max-width: 114rem;
//     }
    
// }
// .newsletters__box {

//         .form {
//              display: flex;
//              flex-direction: column;

//              @include respond(sm) {
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 flex-direction: row;
//              }

//             .form__group {

//                 display: flex;
//                 align-items: center;
//                 flex-direction: column;

//                 @include respond(sm) {
//                     flex-direction: row;
//                 }

                
//             }


//             .form__label  {
//                 color: $secondary-hex;
//                 font-size: 3.5rem;
//                 margin-right: 3rem;
//                 margin-bottom: 3rem;

//                 @include respond(sm) {
//                     font-size: 3.3rem;
//                     margin-bottom: 0; 
//                 }
//                 @include respond(md) {
//                     font-size: 2.5rem;
//                     margin-bottom: 0; 
//                 }
//             }

//             .form__control {
//                 height: 7rem;
//                 padding:2rem 2rem;
//                 width: 100%;
//                 margin-right: 0;
//                 border:0;
//                 font-size: 2.6rem;
//                 color: $gray-hex;
//                 border-radius: 0;
                
  
//                 @include respond(sm) {
//                     width:50rem;
                    
//                 }


//                 @include respond(md) {
//                     font-size: 1.6rem;
//                     height: 4rem;
//                     padding:1rem 2rem;
//                     border-top-left-radius: 3px;
//                     border-end-start-radius: 3px;
//                 }
//                 &:focus {
//                     outline: none;

//                 }
//             }

//             .invalid {
//                 border-bottom: 1px solid red;
//             }



             
//         }

       
//     }

// .btn {
//     height: 7rem;
//     padding:.6rem 2rem;
//     background-color: $secondary-hex;
//     color: $gray-hex;
//     border:0;
   
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 3.3rem;
//     cursor: pointer;
//     border-top-right-radius: 0;
//     border-end-end-radius: 0;

//     @include respond(md) {
//         font-size: 2rem;
//         padding:1rem 2rem;
//         width:150px;
//         height: 4rem;
//         border-top-right-radius: 3px;
//         border-end-end-radius: 3px;
//     }

//     &:active, &:focus {
//         outline: none;
//         border-color:darken($secondary-hex, $amount: 20) ;
//     }

//     &:disabled {
//         cursor: not-allowed;
//     }
// }




// .carousel-indicators {
//     display: none;
//     justify-content: flex-end;
    
//     li {
//         background-color: $secondary-hex;
//     }

//     li.active {
//         background-color:$primary-hex;
//     }
// }



.chatIcon {
    background-color: $gray-hex;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    border-radius: 10rem;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    z-index: 2147483003;
    animation: intercom-lightweight-app-launcher 250ms ease;

}

@keyframes intercom-lightweight-app-launcher {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    
 
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.btn-rounded-corner {
    border-radius: 10px;
}
