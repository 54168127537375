.chairman {
	background-color: #f1f1f1;
	padding: 6rem 3rem;

	&__inner {
		width: 100%;
		display: flex;
		margin: auto;
		flex-direction: column;

		@include respond(lg) {
			flex-direction: row;
			width: 80%;
		}
	}

	&__boss {
		flex: 0 0 400px;
		margin-right: 0;

		@include respond(lg) {
			margin-right: 6rem;
		}

		.fig {
			position: relative;
		}

		.figure {
			width: 240px;
			border-radius: 50%;
			overflow: hidden;
			height: 250px;
			border: 5px solid $white;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
			display: block;
			margin: 0 auto;

			@include respond(sm) {
				width: 50%;
				display: block;
				height: auto;
			}

			@include respond(lg) {
				width: 100%;
				height: auto;
			}

			.img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.figcaption {
			background-color: #fff;
			position: absolute;
			bottom: -40px;
			left: 50%;
			transform: translateX(-50%);
			padding: 5px;
			width: 90%;
			font-size: 2rem;
			z-index: 6;
			text-align: center;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
			color: $gray-hex;
			letter-spacing: 1px;
			font-weight: 700;

			@include respond(sm) {
				width: 53%;
				bottom: -10px;
			}

			@include respond(lg) {
				font-size: 1.4rem;
				width: 90%;
			}

			.span {
				font-weight: 400;
			}
		}
	}

	&__profile {
		flex: 1;
		margin-top: -10rem;

		@include respond(sm) {
			margin-top: 0;
		}

		.span {
			font-size: 2rem;
			font-weight: 700;
			@include respond(lg) {
				font-size: 1.6rem;
			}
		}

		h2 {
			font-size: 2.4rem;
			margin-bottom: 1.5rem;
			color: $gray-hex;
			text-transform: uppercase;
			letter-spacing: 2px;
		}

		p {
			font-size: 2rem;
			// width: 70%;
			color: $gray-hex;
			letter-spacing: 1px;
			text-align: justify;

			@include respond(lg) {
				font-size: 1.6rem;
			}
		}

		& > p + p {
			margin-top: 1.5rem;
		}

		.hr {
			margin-bottom: 1.5rem;
			border: 1px solid rgba($gray-hex, 0.15);
		}
	}
}

.margin-bt {
	margin-bottom: 6rem;
}

.chairman__btn {
	padding: 1.5rem 2rem;
	color: $gray-hex;
	border: 1px solid $gray-hex;
	letter-spacing: 2px;
	font-size: 1.6rem;
	margin-top: 1.5rem;
	cursor: pointer;

	&:hover {
		background-color: $gray-hex;
		color: $secondary-hex;
	}
}
