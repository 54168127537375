.section-politicalParty {
    padding: 6rem 3rem;
    background-color: #f1f1f1;
  
    
    
    h2 {
        font-size: 3.2rem;
        margin-bottom: 4.5rem;
        position: relative;
        letter-spacing: 2px;
        
    
        &::after {
            content: '';
            display: block;
            background-color: rgba($gray-hex, .25);
            margin-top: 1rem;
            height: 1px;
            width: 90%;
        }
    }

    .badge {
        position: absolute;
        background-color:$gray-hex;
        top: 50%;
        right:10%;
        padding: 0 1.5rem;
        color: $secondary-hex;
        letter-spacing: 2px;
        border-radius: 3px;
        font-size: 2rem;
        // transform: translateY(-50%);
    }
}

    .politicalParty {
        ul {
            width: 80%;
            margin: auto;
            list-style: none;
        }

        li {
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            letter-spacing: 2px;
            color: $gray-hex;
        }

        li + li {
            margin-top: 1.5rem;
        }
    }