.localGovernment{
    position: relative;



.localGovernment__body--view{
    padding:3rem 0;
}
.localGovernment__body--add{
   padding:3rem;
   background-color: #fff;
   box-shadow: 0 2px 4px rgba(0,0,0,.12);
   width: 50%;
   margin-top: 3rem;

  
}

.formLG__group {
    margin-bottom: 1.5rem;
}
.formLG__control {
    padding: 15px 20px; 
    width: 100%;   
    font-size: 1.5rem;
   letter-spacing: 2px;

   &:focus {
       outline: none;
       font-weight: 600;
   }
}

.formLG__control1 {
    margin-right: 1.5rem;
}

.lg__btn{
    padding: 15px 20px;
    border: 1px solid $gray-hex;
    background-color: $primary-hex;
    color: #fff;
    font-size: 1.5rem;
    letter-spacing: 2px;
    

    &:hover, &:focus {
        // background-color: $gray-hex;
        color: $secondary-hex;
        outline: none;
        background-color: #f5f5f5;
        color: $gray-hex;
    }
}

.formLG__btn {
    padding: 10px 20px;
    border: 1px solid $gray-hex;
    width: 150px;
    
    font-size: 1.5rem;
    letter-spacing: 2px;
    // background-color: $gray-hex;
    // color: $secondary-hex;
    background-color: $primary-hex;
    color: #fff;
    cursor: pointer;

    &:hover, &:focus {
        background-color: #f5f5f5;
        color: $gray-hex;
        outline: none;
    }

    &:disabled {
        background-color: gray;
        cursor: not-allowed;
    }
}

table.lg {
    border-collapse: collapse;
    width: 50%;
    th, td, tr {
        padding: 10px;
        border: 1px solid $gray-hex;
         font-size: 1.6rem;
    
    }
}
.addwardN {
    position: absolute;
    top:70px;
    width: 43%;
    right: 3rem;
    padding: 15px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.12);

    ul {
        padding: 15px 0;
    }

    li {
        padding-left: 3rem;
        display: flex;
        justify-items: center;
    }
}

.bred {
    margin-left: 10px;
    background-color: red;
    border: 1px solid transparent;
    color: #fff;
    &:hover {
        border: 1px solid $primary-hex;
    }

    &:focus {
        outline: none;
    }

    // p {
    //     font-size: 300;
    // }
}

.lgbn {
    width: 100%;
}


.hr {
    margin-top: 15px;
}

.w1 {
    width: 100%;
    padding:15px 0;
}

.w50 {
    width: 50%;
}

.invalid {
    border: 1px solid red;
}


}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .alert__primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }

  .alert__success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert__failed {
      color: red;
      background-color: #cce5ff;
    border-color: #b8daff;
  }

  .pf {
    font-size: 1.6rem;
  }

  .li {
      display: flex;


      &__span {
          margin-left: auto;
      }
  }