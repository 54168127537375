.config {

    &__bar {

        display: flex;
        justify-content: space-between;
        padding: 3rem 3rem;


        & > * {
            width: 35%;
        }


        &__1 {
            border: 1px solid $primary-hex;
            padding: 15px;
            box-shadow: 0 2px 4px 4px rgba($primary-hex, .2);
        }


        &__2 {
            border: 1px solid $primary-hex;
            padding: 15px;
            box-shadow: 0 2px 4px 4px rgba($primary-hex, .2);
            width: 27%;

            p {
                display: flex;
                justify-content: space-between;

                span {
                    display: block;
                }
            }
        }
    }




    &__date, &__adhoc, &__permanent {
        border: 1px solid $primary-hex;
        margin: 3rem;
        padding: 3rem 6rem;
        box-shadow: 0 2px 4px 4px rgba($primary-hex, .2);
    }

    &__date {

    }

    &__adhoc {

    }


    &__permanent {

    }

    input[type="date"] {
        padding: .5rem .95rem;
        width: 219px;
        border: 1px solid $gray-hex;

        &:focus {
            outline: none;
            border: 1px solid $primary-hex;
        }
    }

    .btnConfig {
        padding: .75rem .95rem;
        background-color: $primary-hex;
        color: $white;
        border: none;
        margin: 1rem 0;
        width: 145px;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }



    .red {
        padding: 1px 5px;
        background-color: red;
        color: #fff;
    }
    .green {
        padding: 1px 5px;
        background-color:green;
        color: #fff;
    }
}