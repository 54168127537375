.viewChairmanship {
	display: flex;
	flex-wrap: wrap;
	background-color: $primary-light-hex;
	padding: 1.5rem 3rem;
	color: #fff;

	& > * {
		border-left: 1px solid #fff;
		padding: 0 15px;
		margin-top: 15px;

		&:hover {
			background-color: #1bf5cd;
			color: #333;
			cursor: pointer;
		}

		&:last-child {
			border-right: 1px solid #fff;
		}
	}

	&__header {
		color: $primary-light-hex;
		text-transform: uppercase;

		&::after {
			content: '';
			height: 1px;
			width: 100%;
			background-color: $primary-light-hex;
			margin-bottom: 4rem;
			margin-top: 1rem;
			display: block;
		}
	}
}

.aspirantList {
	width: 100%;
	border-collapse: collapse;

	tr,
	th,
	td {
		border: 1px solid $primary-light-hex;
		padding: 15px;
	}

	tr:hover {
		background-color: #cecece;
		color: #333;
	}

	thead {
		background-color: $primary-light-hex;
		color: #fff;

		th {
			border: 1px solid #013f34;
		}

		& tr:hover {
			background-color: $primary-light-hex;
			color: #fff;
		}
	}
}

.publish__btn_sp_primary {
	width: 30rem;
	padding: 2rem 3rem;
	background-color: $primary-light-hex;
	font-size: 1.6rem;
	color: #fff;
	border: 1px solid $primary-light-hex;
	cursor: pointer;

	&:hover {
		background-color: transparent;
		color: $primary-light-hex;
	}

	&:focus {
		outline: none;
		border: 1px solid darken($primary-light-hex, 20);
	}
}


