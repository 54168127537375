.countdown {
    border-top: 3px solid #4F4C5D;
    margin-top: -12.85px;
    
    @include respond(sm) {
        margin-top: -3.85px;

    }


}

.countdown__title {
    text-align: center;
    font-size: 2.8rem;
    margin: 3rem 0;
    color: maroon;
    font-weight: 700;
    text-transform: uppercase;
   

    @include respond(lg){
        font-size: 2.8rem;
        animation: election2021 1s ease-in;
    }

}

.countdown__body {
    // height: 35rem;
    // margin: 1.5rem 0;
    background-color: #f5f5f5;
    padding: 6rem 3rem;

    @include respond(lg) {
        padding: 6rem 15rem;
    }
}


.countdown__body__ul {
    list-style: none;
    display: flex;
    justify-content: space-between;


    &__li {
        width: 11rem;
        height: 11rem;
        background-color: #4f4c5d;
        color: #f8fba0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 2rem;
        line-height: 1.1;

        @include respond(md) {
            width: 15rem;
            height: 15rem;
            font-size: 2.4rem;
        }

        @include respond(lg) {
            width: 18rem;
            height: 18rem;
        }
    }

    &__span {
     
 font-size: 7.2rem;
    }
    
}
.countdown__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    margin: 3rem 0;
    font-size: 1.6rem;

    @include respond(sm) {
        justify-content: space-between;
    }

    @include respond(lg) {
        font-size: 2.4rem;
        padding: 0 15rem;
    }
    &__date {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    &__notice a {
        padding: 10px 5px;
        background:  #4f4c5d;
        letter-spacing: 1px;
        border-radius: 10px;
        font-size: 1.1rem;
        color: #f8fba0;
        font-weight: 700;
        cursor: pointer;
        outline: none;
        text-align: center;
        text-decoration: none;
        border: 1px solid $gray-hex;
        display: block;

        @include respond(sm) {
            padding: 13px 10px;
            border-radius: 10px;
        }
        

        @include respond(lg){
            padding: 13px 18.76px;
            font-size: 1.5rem;
        }



        &:hover{

            // background-color: #4f4c5d;
            // color: rgba(255, 255, 255, .45);
            filter: brightness(110%);
            outline: none;
        }

    }

}
.completed {
    text-align: center;
}
.completed__title {
    font-size: 3.2rem;
}

@keyframes election2021 {
    0% {
        color: #4F4C5D; 
    }
    25% {
        color: #0317b6;
    }
    50% {
        color: #07a556;
        transform: scale(1.2);
    }
    75% {
        color: #c0039a;
    }
    100% {
        color: maroon;
    }
}